.SpeakerNote {
    position: relative;
    display: inline-block;
    margin-block-end: 0.8em;
    margin-bottom: 2px;
    color: #1A936F
  }

  .StudentNote {
    position: relative;
    display: inline-block;
    margin-block-end: 0.8em;
    margin-bottom: 2px;
    color: #067BC2
  }