.settingsPage {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    margin-left: 10rem;
    margin-right: 2rem;
}

@media only screen and (max-device-width: 600px) {
    .settingsPage {
        margin-top: 150px;
        margin-left: 5rem;
    }
}


.close-button {
    min-width: 75%;
    font-size: 20px;
    text-align: right;
}

.cut-type-heading {
    //styleName: Unit/Unit Subheader;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0px;
    margin-bottom: 0.5rem;
    margin-top: 2.5rem;
    text-align: left;
    color:var(--semantic-colors-unit-text, #0F3569);
};

.top-cut-type-heading {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0px;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    text-align: left;
    color:var(--semantic-colors-unit-text, #0F3569);
}

.settings-buttons {
    font-family: Lora;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 38.4px */
    margin-right: 14px;
}

.all-settings-buttons {
    font-family: Lora;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 38.4px */
    margin-right: 14px;
    margin-bottom: 2rem;
}