.NavBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #8C8594;
    position: fixed;
    top: 0px;
    z-index: 4;
    background-color: #F6F7F9;
    align-items: center;
    font-size: .85rem;
    padding: 10px;
}

.ButtonContainer {
    padding-right: 1rem;
}

h4 {
    font-size: 1.2rem;
    margin: .6rem;
}

@media only screen and (max-device-width: 600px) {
    .NavBar {
        font-size: 1.5rem;
    }
    h4 {
        font-size: 2rem;
        padding: .6rem;
    }
    .menu_p {
        font-size: 2rem;
    }
}






.ButtonConatiner {
    align-self: flex-end;
    flex-grow: 1;
    padding-right: 3vw;
}

.TitleContainer {
    font-family: 'Montserrat', sans-serif;
    color: #4B296B;
    margin-left: 1vw;
    text-align: left;
    align-self: flex-start;
    flex-grow: 1;
}