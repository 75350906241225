.PerformerNote {
    position: relative;
    display: inline-block;
    margin-block-end: 0.8em;
    margin-bottom: 2px;
    color: #1A936F
  }

  @media only screen and (max-device-width: 600px) {
    .end-line, .line-container .stage-direction .split-line {
        font-size: 24pt;
    }
}