@use 'src/styles/_colors.scss';

.end-line, .stage-direction {
    color: var(--semantic-colors-stage-direction, #473C53);
    font-family: Poppins;
    font-size: 19.2px;
    font-style: italic;
    font-weight: 400;
    line-height: 30.72px
};

.end-line, .split-line {
    text-align: right;
}

.line-container {
    display: flex;
    justify-content: space-between
};

.line-num {
    margin-right: 25px;
    color: grey;
    font-size: 14pt;
};

.line {
    color: var(--semantic-colors-primary-text, #190B28);    
    font-family: Lora;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 38.4px */
    margin-block-end: 16px;
    margin-block-start: 0px
};

.q2Button {
    color: #8C8594;
    font-size: 16px;
}

.line-content {
    width: 700px;
};

.speaker, .speech-heading {
    color: var(--semantic-colors-character-heading, #5E5468);
    font-family: Lora;
    font-size: 16.8px;
    font-style: normal;
    font-weight: 400;
    padding-top: 10px;
    line-height: 160%; /* 38.4px */
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-block-start: 0em;
    margin-block-end: 0px;
}



.split-line {
    color: var(--semantic-colors-primary-text, #190B28);
    font-family: Lora;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 38.4px */
    margin-block-end: 0px;
    margin-block-start: 0px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end
};

.lineNumber {
    float: right;
    color: var(--semantic-colors-line-number, #8C8594);
    font-family: Lora;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
};

.cutLine {
    opacity: 0.4;
}

.hiddenLine {
    border: none;
    border-left: 2px dashed var(--semantic-colors-hidden-text, #BAB6BF);
    height: 30px;
    margin-block-start: 2px;
    margin-block-end: 0px;
}

@media only screen and (max-device-width: 600px) {
    .speaker, .speech-heading {
        font-size: 24pt; 
    }
    .end-line, .line-container, .stage-direction, .split-line {
        font-size: 24pt;
    }
}