@use 'src/styles/_colors.scss';

.above {
    position: relative;
    display: inline-block;
    margin-block-end: 0.8em;
    margin-bottom: 2px;
    text-decoration: underline dotted #da4167
  }

  .StudentNote {
    position: relative;
    display: inline-block;
    margin-block-end: 0.8em;
    margin-bottom: 2px;
    color: #da4167
  }

  .above::before {
    position: absolute;
    top: 90%;
    height: 6px;
    width: 100%;
    content: "";
  }
  .above .belowgloss {
    position: absolute;
    width: 100%;
    color: #da4167;
    white-space: nowrap;
    left: 0;
    top: 60%;
    font-size: 0.75em;
    margin-block-start: 0em;
    text-align: center;

  }

  .post-line {
    display: flex;
    flex-direction: row;
  }

.end-line, .stage-direction {
    color: var(--semantic-colors-stage-direction, #473C53);
    font-family: Poppins;
    font-style: italic;
    font-weight: 400;
    line-height: 30.72px
};

.end-line {
  text-align: right;
}

.line-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between
};

.line-num {
    margin-right: 25px;
    color: grey;
    font-size: 14pt;
};

.split-line {
  color: var(--semantic-colors-primary-text, #190B28);
  font-family: Lora;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 38.4px */
  margin-block-end: 0px;
  margin-block-start: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
};

.alt-button {
  justify-self: end;
  width: 0px;
}

.lineNumber {
    float: right;
    color: var(--semantic-colors-line-number, #8C8594);
    font-family: Lora;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
};

@media only screen and (max-device-width: 600px) {
  .end-line, .line-container, .stage-direction, .split-line {
      font-size: 24pt;
  }
}